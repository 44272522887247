import React, {useState, useCallback} from 'react';
import Cookies from 'js-cookie';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import '../../../assets/css/connect.css';
import Footer from '../../Shared/Footer';
import withUTMParams from '../../HOC/withUTMParams';
import ARVRHeadingSection from './Section/ARVRHeadingSection';
import GrowthPartnerSection from '../Section/GrowthPartnerSection';
import BuildTeamSection from '../Section/BuildTeamSection';
import WhyClientPreferUsSection from '../Section/WhyClientPreferUsSection';
import ClientsLogoSection from '../Section/ClientsLogoSection';
import AwardsSection from '../Section/AwardsSection';
import ClientsTestimonialSection from '../Section/ClientsTestimonialSection';
import ContactUsSection from '../Section/ContactUsSection';
import BlogPostsSection from '../Section/BlogPostsSection';
import ARVRProjectsSection from './Section/ARVRProjectsSection';
import ServiceInfoSection from '../Section/ServiceInfoSection';
import benefitsIcon from '../../../assets/images/connect/service-info/benefits.svg';
import investingIcon from '../../../assets/images/connect/service-info/investing.svg';

import CookieBanner from '../../Shared/CookieBanner';

const SERVICE_INFO = [
  {
    title: (
      <h2>
        Investing in Augmented
        <br /> Reality Services
      </h2>
    ),
    description:
      'Investing in AR services for your company is a game-changer. With the rising preference for online shopping, real-time product display through augmented reality has become indispensable. Research reveals that e-commerce stores with AR experiences enjoy a staggering 94% higher conversion rate, while 40% of shoppers are willing to pay extra for the augmented reality viewing experience.',
    imgSrc: investingIcon,
  },
  {
    title: <h3>Benefits of AR Experiences</h3>,
    description:
      'Augmented reality enables remote product experiences and enhances shopping for various products, including furniture, apparel, cosmetics, and interior design. Our experience developers can provide augmented reality services tailored to your business needs.',
    imgSrc: benefitsIcon,
  },
];

function ARVRConnectPageTemplate({
  location,
  metaTitle,
  metaDescription,
  pageviewTitle,
  title,
  subtitles,
  variant = 'A',
}) {
  const [, setCookieState] = useState(false);

  const acceptCookieHandler = useCallback(() => {
    Cookies.set('permission-cookies', 'perm-granted', {secure: true}, {expires: 150});
    setCookieState(true);
  }, []);
  React.useEffect(() => {
    ReactGA.set({
      title: variant === 'A' ? pageviewTitle : `${pageviewTitle}.B`,
    });
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href="https://tintash.com/connect/ar-vr/ar-vr-development/" />
      </Helmet>
      <ARVRHeadingSection title={title} subtitles={subtitles} variant={variant} />
      <ClientsLogoSection />
      <ServiceInfoSection serviceInfo={SERVICE_INFO} />
      <WhyClientPreferUsSection />
      <GrowthPartnerSection />
      <BuildTeamSection />
      {/* <ExpertiseServicesSection /> */}
      <ARVRProjectsSection />
      <BlogPostsSection />
      <AwardsSection />
      <ClientsTestimonialSection />
      <ContactUsSection />
      {!Cookies.get('permission-cookies') && <CookieBanner acceptHandler={acceptCookieHandler} />}
      <Footer showContactSection={false} showPortfolioSection={false} />
    </div>
  );
}

export default withUTMParams(ARVRConnectPageTemplate);
