import React from 'react';
import ARVRConnectPageTemplate from '../../../components/Connect/AR-VR';

const subtitles = {
  A: (
    <>
      From concept to creation, our product and technical experts will work with you to build
      incomparable AR experiences that transform reality and provide a total immersive experience.
    </>
  ),
  B: 'Leverage our expert AR/VR developers to elevate your project, delivering the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <ARVRConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Augmented Reality Services"
      subtitles={subtitles}
      metaTitle="Augmented Reality Services - Tintash"
      metaDescription="Tintash offers industry leading Augmented Reality Services, providing incomparable AR experiences in beauty, apparel, home decor & more!"
      pageviewTitle="Connect/AR-VR/AR-VR.Development"
    />
  );
}
export default index;
