import React from 'react';
import ProjectCard from '../../../Shared/ProjectCard/ProjectCard';

const PROJECTS = [
  {
    id: 'All3d',
    featuredImageSrc: 'all3d.webp',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/all3d.webp').default,
    projectDescription:
      'A platform for creating and delivering 3D Models & Services to provide immersive visual experiences for online shopping and personalized services.',
    projectName: 'All3d',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [{value: 'Real Estate', label: 'Real Estate'}],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      ],
    },
  },
  {
    id: 'decorist',
    featuredImageSrc: 'decorist.webp',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/decorist.webp').default,
    projectDescription:
      'Decorist’s innovative room builder application allows designers to get photorealistic renders of designed rooms in record time.',
    projectName: 'decorist',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
        {
          value: 'Photo Editing and Printing',
          label: 'Photo Editing and Printing',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'Unity', label: 'Unity'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
        {value: 'Jenkins', label: 'Jenkins'},
      ],
    },
  },
  {
    id: 'bed-bath-beyond',
    caseStudyUrl: '',
    featuredImageSrc: 'bed-bath-beyond.webp',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/bed-bath-beyond.webp')
      .default,
    projectDescription:
      "Bed Bath and Beyond's app lets end-users visualize products by virtually placing them in their own room, providing a personalized and interactive shopping experience.",
    projectName: 'bed-bath-beyond',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {value: 'Lifestyle', label: 'Lifestyle'},
        {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'ARKit', label: 'ARKit'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      ],
    },
  },
];

function ARVRProjectsSection() {
  return (
    <section className="web-dev__our-work-section">
      <div className="connect-web__expertise-heading blockchain-clients">Our Amazing Clients</div>
      <div className="web-dev__our-work-projects-container">
        {PROJECTS.map((project, i) => (
          <div key={project.id} className="web-dev__our-work-project-container">
            <ProjectCard
              {...project}
              featuredImageLoading="lazy"
              projectDescriptionClass="web-dev__our-work-project-description"
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default ARVRProjectsSection;
